import produce from "immer";
import { getLinkTextIcon } from "../../settings";
import { TENANT_STATUS } from "../constants/routes";

export const initials = (name) => {
  if (name) {
    //splits words to array
    const nameArray = name.split(" ");

    let initials = "";

    //if it's a single word, return 1st and 2nd character
    if (nameArray.length === 1) {
      return nameArray[0].charAt(0) + "" + nameArray[0].charAt(1);
    } else {
      initials = nameArray[0].charAt(0);
    }
    //else it's more than one, concat the initials in a loop
    //we've gotten the first word, get the initial of the last word

    //first word
    for (let i = nameArray.length - 1; i < nameArray.length; i++) {
      initials += nameArray[i].charAt(0);
    }
    //return capitalized initials
    return initials.toUpperCase();
  }
};

export const setupRTPE = (flag, setConfig) => {
  if (flag) {
    setConfig((prev) => {
      return produce(prev, (draft) => {
        const matched = draft.links.find((link) => link.href === "/offers");
        if (matched) {
          matched.href = "/rtpe/prices";
        }
      });
    });
  }
};

// @deprecated - use the setupHeaderLinksV2 method instead
export const setupHeaderLinks = (flag, setConfig, url) => {
  setConfig((prev) => {
    return produce(prev, (draft) => {
      const matchedItem = draft.links.find((link) => link.href === url);
      if (matchedItem) {
        matchedItem.visible = !!flag;
      }
    });
  });
};

export const getFilteredHeaderLinks = (
  draftLinks,
  flagConfig,
  isNewTerminologyEnabled,
  isNewL1LinksEnabled,
  shouldFilterForProvisionedProducts,
  tenantStatus,
  orgProducts,
) => {
  return draftLinks.slice().map((link) => {
    // Home / Overview link should always be shown
    if (link.text === 'Overview' || link.text === 'Home') {
      link.visible = true;
    }
    // If we need to filter products based on what products were provisioned by DX
    else if (!!shouldFilterForProvisionedProducts && (
      tenantStatus !== TENANT_STATUS.ACTIVE || !orgProducts.includes(link?.productId)
    )) {
      link.visible = false;
    }

    // Determine visibility based on feature flag configuration
    else if (flagConfig[link.productId] !== undefined) {
      link.visible = !!flagConfig[link.productId];
    }

    // Custom logic for RTPE
    const path = window.location.pathname.split("/")[1];
    const linkPath = link.href.replace("/#", "").split("/")[1];
    if ((link.productId === "OFFERS" && path === "rtpe") || path === linkPath) {
      return {
        ...link,
        active: true,
      };
    }

    // Get new text and icons
    const newTextIcon = getLinkTextIcon({
      productId: link.productId,
      href: link.href,
      isNewTerminologyEnabled,
      isNewL1LinksEnabled,
    });
    link.text = newTextIcon.text;
    link.icon = newTextIcon.icon;
    link.href = newTextIcon.href;
    return link;
  });
}

export const setupHeaderLinksV2 = (
  setConfig,
  flagConfig,
  isNewTerminologyEnabled,
  isNewL1LinksEnabled,
  shouldFilterForProvisionedProducts,
  tenantStatus,
  orgProducts,
  accountId,
) => {
  setConfig((prev) => {
    return produce(prev, (draft) => {
      if (!accountId) {
        const logOutNavigation = [{
          label: "Logout",
          link: localStorage.getItem("isLoggedInWithIdV2")
            ? "/auth/v2/login/"
            : "/",
        }];
    
        draft.links = [];
        draft.user.userNav = logOutNavigation;
        return;
      }

      draft.links = getFilteredHeaderLinks(
        draft.links,
        flagConfig,
        isNewTerminologyEnabled,
        isNewL1LinksEnabled,
        shouldFilterForProvisionedProducts,
        tenantStatus,
        orgProducts
      );
    });
  });
};
