import React, { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-react";
import { ConfigContextProvider } from "./hooks/header";
const DS4Switch = lazy(() => import("./DS4Switch"));

export const config = {
  url: process.env.UNLEASH_PROXY_URL,
  clientKey: process.env.UNLEASH_CLIENT_KEY,
  appName: "copilot-header",
  disableRefresh: true,
  context: {
    userId: window.sessionStorage.getItem("accountId") ?? "",
  },
};

export const client = new UnleashClient(config);

export const RootApp = () => {
  return (
    <Suspense fallback={<></>}>
      <FlagProvider unleashClient={client}>
        <BrowserRouter>
          <ConfigContextProvider>
            <DS4Switch />
          </ConfigContextProvider>
        </BrowserRouter>
      </FlagProvider>
    </Suspense>
  );
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: RootApp,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
